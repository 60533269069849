export default (
  number: string,
  locale: string | string[],
  currency: string
) => {
  const stringNumber = Number(number.replace(/\D/g, '')) / 100

  const formator = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency
  })

  return formator.format(stringNumber)
}

export default (string: string, mask: string) => {
  const stringChars = string.replace(/\D/g, '').split('')
  const splitedMask = mask.split('')
  const groupedChars: string[] = []

  splitedMask.forEach((item) => {
    const lastIndex = groupedChars.length - 1
    const lastItem = groupedChars[lastIndex]

    if (item !== '#' && lastItem !== '#') {
      return (groupedChars[lastIndex] = lastItem + item)
    }

    return groupedChars.push(item)
  })

  let chars: string[] = []

  groupedChars.forEach((item, index) => {
    const nextIndex = index + 1
    const nextItem = groupedChars[nextIndex] || ''

    if (item === '#' && nextItem !== '#') {
      groupedChars.splice(nextIndex, 1)
      return chars.push('#' + nextItem)
    }

    return chars.push(item)
  })
  chars = chars.slice(0, stringChars.length)

  const firstMaskDigit = mask.charAt(0)

  if (firstMaskDigit !== '#') {
    const firstChar = chars[0]
    chars[0] = firstMaskDigit + firstChar
  }

  const masked = chars.map((maskChar) => {
    const currentStringChar = stringChars[0] || ''
    stringChars.shift()

    return maskChar.replace(/#/, currentStringChar)
  })

  return masked.join('')
}

export const normalizeNumber = (value: number) =>
  Number(Number(value).toFixed(2))

export const maxNumber = (value: number, max?: number) => {
  if (max) {
    return value > max * 100 ? max * 100 : value
  }

  return value
}

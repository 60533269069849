import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef
} from 'react'
import maskNumber from '../mask/number'

export interface IMaskedNumberProps
  extends React.HTMLAttributes<HTMLInputElement> {
  mask: string
  onChangeValue?: (value: string) => any
}

export interface IMaskedNumberRef {
  focus: () => void
  blur: () => void
  defaultValue?: string
}

const MaskedNumberComponent: React.ForwardRefRenderFunction<
  IMaskedNumberRef,
  IMaskedNumberProps
> = ({ mask, onChangeValue, defaultValue, ...props }, ref) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  useImperativeHandle(ref, () => ({
    focus: () => inputRef.current?.focus(),
    blur: () => inputRef.current?.blur()
  }))

  const onInputChange = useMemo(
    () => (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault()
      event.stopPropagation()
      if (inputRef.current) {
        const lastValue = event.target.dataset.last || ''
        const currentValue = event.target.value

        let value = maskNumber(currentValue, mask)

        if (currentValue.length < lastValue.length) {
          const lastCurrentChar = lastValue.slice(-1)
          if (/\D/.test(lastCurrentChar)) {
            const lastDigits = lastValue.replace(/\D/g, '')
            value = maskNumber(lastDigits, mask).slice(0, -1)
          }
        }

        inputRef.current.value = value
        inputRef.current.dataset.last = value
        onChangeValue && onChangeValue(value.replace(/\D/g, ''))
      }
    },
    [mask, inputRef.current, onChangeValue]
  )

  useEffect(() => {
    if (inputRef.current && defaultValue) {
      const value = maskNumber(defaultValue.toString(), mask)
      inputRef.current.value = value
      inputRef.current.dataset.last = value
    }
  }, [defaultValue, mask])

  return (
    <input
      type='text'
      ref={inputRef}
      onChange={onInputChange}
      {...props}
      data-last=''
    />
  )
}

export const MaskedNumber = forwardRef(MaskedNumberComponent)

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef
} from 'react'
import maskCurrency from '../mask/currency'

import { maxNumber, normalizeNumber } from '../utils/number'

export interface IMaskedCurrencyProps
  extends React.HTMLAttributes<HTMLInputElement> {
  locale: string | string[]
  currency: string
  onChangeValue?: (value: number) => any
  defaultValue?: number
  max?: number
}

export interface IMaskedCurrencyRef {
  focus: () => void
  blur: () => void
  getValue: () => number
  setValue: (value: number) => void
}

const MaskedCurrencyComponent: React.ForwardRefRenderFunction<
  IMaskedCurrencyRef,
  IMaskedCurrencyProps
> = ({ locale, currency, onChangeValue, defaultValue, max, ...props }, ref) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  useImperativeHandle(ref, () => ({
    focus: () => inputRef.current?.focus(),
    blur: () => inputRef.current?.blur(),
    getValue: () =>
      (Number(inputRef.current?.value.replace(/\D/g, '')) || 0) / 100,
    setValue: (value) => {
      if (inputRef.current) {
        inputRef.current.value = maskCurrency(
          normalizeNumber(maxNumber(value * 100, max)).toString(),
          locale,
          currency
        )
      }
    }
  }))

  const onChange = useMemo(
    () => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (inputRef.current) {
        const nodeValue = normalizeNumber(
          maxNumber(Number(event.target.value.replace(/\D/g, '')), max)
        )
        const value = maskCurrency(nodeValue.toString(), locale, currency)
        inputRef.current.value = value
        onChangeValue &&
          onChangeValue((Number(value.replace(/\D/g, '')) || 0) / 100)
      }
    },
    [locale, currency, onChangeValue, inputRef.current, max]
  )

  useEffect(() => {
    if (inputRef.current && !props.placeholder) {
      inputRef.current.placeholder = maskCurrency('', locale, currency)
    }
  }, [props.placeholder])

  useEffect(() => {
    if (inputRef.current && defaultValue) {
      inputRef.current.value = maskCurrency(
        normalizeNumber(maxNumber(defaultValue * 100, max)).toString(),
        locale,
        currency
      )
    }
  }, [defaultValue, locale, currency, max])

  return <input type='text' ref={inputRef} onChange={onChange} {...props} />
}

export const MaskedCurrency = forwardRef(MaskedCurrencyComponent)
